import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "ScType",
  props: {
    scType: {
      type: [Number],
      default: undefined
    }
  },
  data: function data() {
    return {
      scoptions: [{
        value: 0,
        label: '全部'
      }, {
        value: 1,
        label: '橙券'
      }, {
        value: 30,
        label: '海威'
      }, {
        value: 2,
        label: '自营'
      }, {
        value: 3,
        label: '福禄'
      }, {
        value: 4,
        label: '惠享派'
      }, {
        value: 5,
        label: '多鲸V1'
      }, {
        value: 26,
        label: '多鲸V2'
      }, {
        value: 6,
        label: '星凯'
      }, {
        value: 7,
        label: '彦浩'
      }, {
        value: 8,
        label: '伯爵'
      }, {
        value: 9,
        label: '星空云'
      }, {
        value: 10,
        label: '悠品'
      }, {
        value: 11,
        label: '券码无忧'
      }],
      loading: false
    };
  }
};