var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-select",
        {
          attrs: {
            value: _vm.scType,
            clearable: "",
            placeholder: "请选择供应商",
            filterable: "",
            "reserve-keyword": "",
            loading: _vm.loading
          },
          on: {
            change: function($event) {
              return _vm.$emit("update:scType", $event)
            }
          }
        },
        _vm._l(_vm.scoptions, function(item) {
          return _c(
            "el-option",
            {
              key: item.value,
              attrs: { label: item.label, value: item.value }
            },
            [_c("span", [_vm._v(_vm._s(item.label))])]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }